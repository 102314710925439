const jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import Rails from '@rails/ujs';
Rails.start();

require("@nathanvda/cocoon")

require("bootstrap");
import '@fortawesome/fontawesome-free/js/all'
import "../stylesheets/fonts/style"
import "../stylesheets/application"
import {convertToHalfWidth} from "./custom"
require.context("../images/", true)

function setNumberEventListener() {
  document.querySelectorAll(".CommaNumberInput").forEach(input => {
    var is_positive = $(input).data('positive') !== false
    input.value = addCommasToNumber(input.value, false, is_positive)
    input.addEventListener('focusout', function() {
      var number = replaceJapaneseNumbers(input.value)      
      number = addCommasToNumber(number, false, is_positive)
      input.value = number
    });
  })
}
setNumberEventListener();

function setNumber() {
  document.querySelectorAll(".NumberInput").forEach(input => {
    input.value = checkNumberInput(input.value)
    input.addEventListener('focusout', function() {
      var number = replaceJapaneseNumbers(input.value)
      number = checkNumberInput(number)
      input.value = number
    });
  })
}
setNumber();

function addCommasToNumber(value, dash, positive = true) {
  let decimals = value.split(".")[1]
  let number = value;
  if (decimals) number = number.replace(`.${decimals}`, "")
  number = number.replace(/-+/g, '-').replace(positive ? /\D/g : /[^0-9-]/g, ''); 
  if (number !== "") {
    number = parseInt(number).toLocaleString()
  }
  if (value.startsWith("-") && dash) {
    number = `-${number}` 
  }
  if (decimals) number += `.${decimals}`
  return isNaN(number.replaceAll(',', '')) || number == '' ? 0 : number
}

function replaceJapaneseNumbers(value) {
  let number = value.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 65248);
  });
  return number
}

function checkNumberInput(value) {
  let number = value.replace(/\D/g, '');
  return number
}

$(document).ready(() => {
  $(".half-width-only").change(function() {
    $(this).val(convertToHalfWidth($(this).val()));
  });

  $(".number-half-width-only").change(function() {
    $(this).val(convertToHalfWidth($(this).val()).replace(/[\D\s\._\-]+/g, ""));
  });

  $(".input_part").bind("input", function() {
    const $this = $(this);
    if ( $this.val().length >= parseInt($this.attr("maxlength"), 10) ) {
      $this.parent(".input-part-col").next().find('.input_part').focus();
    }
  });

  $(".RadioFunctionalCheckbox").change(function() {
    const $CheckboxCustomGroup = $(this).closest(".RadioFunctionalCheckboxGroup");
    $CheckboxCustomGroup.find(".RadioFunctionalCheckbox").not(this).prop('checked', false);
  });

  $(".RadioCheckbox").change(function() {
    const name = $(this).attr('name');
    $(`.RadioCheckbox[name='${name}']`).not(this).prop('checked', false);
  });

  $(".RadioCheckboxInput").on('change', function() {
    const $SelectedRadioCheckboxGroup = $(this).closest(".RadioCheckboxGroup");
    const $RadioCheckboxGroupContainer = $SelectedRadioCheckboxGroup.closest(".RadioCheckboxGroupContainer");
    const $OtherRadioCheckboxGroup = $RadioCheckboxGroupContainer.find(".RadioCheckboxGroup").not($SelectedRadioCheckboxGroup);

    if ($(this).is(":checked")) {
      $SelectedRadioCheckboxGroup.find(".RadioCheckboxInput").prop('readonly', false);
      $SelectedRadioCheckboxGroup.find("input:text").attr('readonly', false);
      $SelectedRadioCheckboxGroup.find('input[type="date"]').attr('readonly', false);
      $SelectedRadioCheckboxGroup.find("input:radio").attr('disabled', false);
      $SelectedRadioCheckboxGroup.find('.required-input').prop('required', true)
    } else {
      $SelectedRadioCheckboxGroup.find(".RadioCheckboxInput").prop('checked', false);
      $SelectedRadioCheckboxGroup.find(".RadioOptionNull").attr('checked', true);
      $SelectedRadioCheckboxGroup.find("input:text").not('.RadioNoZero').prop('value', 0);
      $SelectedRadioCheckboxGroup.find("input:text").attr('readonly', true);
      $SelectedRadioCheckboxGroup.find("input:text").val('');
      $SelectedRadioCheckboxGroup.find('input[type="date"]').not('.RadioNoZero').prop('value', 0);
      $SelectedRadioCheckboxGroup.find('input[type="date"]').attr('readonly', true);
      $SelectedRadioCheckboxGroup.find('input[type="date"]').val('');
      $SelectedRadioCheckboxGroup.find("input:radio").not('.RadioOptionNull').attr('disabled', true);
      $SelectedRadioCheckboxGroup.find(".required-input").removeAttr('required');
    }
    $OtherRadioCheckboxGroup.find(".RadioCheckboxInput").prop('checked', false);
    $OtherRadioCheckboxGroup.find(".RadioOptionNull").attr('checked', true);
    $OtherRadioCheckboxGroup.find("input:text").not('.RadioNoZero').prop('value', 0);
    $OtherRadioCheckboxGroup.find("input:text").attr('readonly', true);
    $OtherRadioCheckboxGroup.find("input:text").val('');
    $OtherRadioCheckboxGroup.find('input[type="date"]').not('.RadioNoZero').prop('value', 0);
    $OtherRadioCheckboxGroup.find('input[type="date"]').attr('readonly', true);
    $OtherRadioCheckboxGroup.find('input[type="date"]').val('');
    $OtherRadioCheckboxGroup.find("input:radio").not('.RadioOptionNull').attr('disabled', true);
    $OtherRadioCheckboxGroup.find(".required-input").removeAttr('required');
  });

  document.querySelectorAll(".CommaNumberInputWithDash").forEach(input => {
    input.value = addCommasToNumber(input.value, true)
    input.addEventListener('focusout', function() {
      var number = replaceJapaneseNumbers(input.value)
      number = addCommasToNumber(number, true)
      input.value = number
    });
  })

  $(".AssignValueByInputName").keyup(function() {
    const name = $(this).attr('name');
    const value = $(this).val();
    $(`.AssignValueByInputName[name='${name}']`).val(value);
  });
})

window.printPageArea = function(printableArea) {
  const $printerDiv = $('<div class="printContainer"></div>');
  $printerDiv.html($(printableArea));
  $('body').append($printerDiv).addClass("printingContent");
  window.print();
  $printerDiv.remove();
  $('body').removeClass("printingContent");
}

// new ui
$('.tricolon-menu-icon').click(function() {
  $(this).next('.tricolon-menu').toggle();
  $(this).nextAll('.tricolon-menu-bg').toggle();
});

$('.tricolon-menu-bg').click(function() {
  $(this).hide();
  $(this).prev('.tricolon-menu').toggle();
});

$(document).on('click', '.close-button', function() {
  $(this).parents().find('.flash-message').slideUp('fast');
});

// 記述例を使用ボタン
function setPlaceholder() {
  $(document).on('click', '.copy-placeholder', function(event) {
    var parent = event.target.closest(".nested-fields");
    if(parent) {
      const dataValue1 = $(this).data('copy-placeholder1');
      let tar1 = parent.querySelector('[data-copy-apply="' + dataValue1 + '"]')
      tar1.value = tar1.placeholder;
      const dataValue2 = $(this).data('copy-placeholder2');
      if(dataValue2) {
        let tar2 = parent.querySelector('[data-copy-apply="' + dataValue2 + '"]')
        tar2.value = tar2.placeholder;
      }
      const dataValue3 = $(this).data('copy-placeholder3');
      if(dataValue3) {
        let tar3 = parent.querySelector('[data-copy-apply="' + dataValue3 + '"]')
        tar3.value = tar3.placeholder;
      }
      const dataValue4 = $(this).data('copy-placeholder4');
      if (dataValue4) {
        let tar4 = parent.querySelector('[data-copy-apply="' + dataValue4 + '"]')
        tar4.value = tar4.placeholder;
      }
      const dataValue5 = $(this).data('copy-placeholder5');
      if(dataValue5) {
        let tar5 = parent.querySelector('[data-copy-apply="' + dataValue5 + '"]')
        tar5.value = tar5.placeholder;
      }
    } else {
      const dataValue1 = $(this).data('copy-placeholder1');
      const tar1 = $('[data-copy-apply="' + dataValue1 + '"]');
      tar1.val(tar1.attr('placeholder'));
      const dataValue2 = $(this).data('copy-placeholder2');
      if(dataValue2) {
        const tar2 = $('[data-copy-apply="' + dataValue2 + '"]');
        tar2.val(tar2.attr('placeholder'));
      }
      const dataValue3 = $(this).data('copy-placeholder3');
      if(dataValue3) {
        const tar3 = $('[data-copy-apply="' + dataValue3 + '"]');
        tar3.val(tar3.attr('placeholder'));
      }
      const dataValue4 = $(this).data('copy-placeholder4');
      if(dataValue4) {
        const tar4 = $('[data-copy-apply="' + dataValue4 + '"]');
        tar4.val(tar4.attr('placeholder'));
      }
      const dataValue5 = $(this).data('copy-placeholder5');
      if(dataValue5) {
        const tar5 = $('[data-copy-apply="' + dataValue5 + '"]');
        tar5.val(tar5.attr('placeholder'));
      }
    }
  });
}
setPlaceholder();

//職歴レコード数を制限する
function check_to_hide_or_show_add_link() {
  if ($('#support-staff-history-fields .nested-fields:visible').length == 6) {
    $('#support-staff-history-button').hide();
  } else {
    $('#support-staff-history-button').show();
  }
}
check_to_hide_or_show_add_link();

//職歴レコードのプレイスホルダー
function support_staff_job_history_year_placeholder() {
  var year_lists = ["2010", "2010", "2017", "2019"];
  var index = 0;
  document.querySelectorAll(".historyYear").forEach(input => {
    if (index <= 3) {
      if (input.parentElement.parentElement.style.display != "none") {
        input.placeholder = year_lists[index]
        index += 1;
      }
    }
  });
}
support_staff_job_history_year_placeholder();

function support_staff_job_history_detail_placeholder() {
  var detail_lists = ["○○大学○○学部卒業", "株式会社SMILEVISA 生産第一課配属", "生産第一課課長", "人事部　人事部長　現在に至る"];
  var index = 0;
  document.querySelectorAll(".historyDetail").forEach(input => {
    if (index <= 3) {
      if (input.parentElement.parentElement.style.display != "none") {
        input.placeholder = detail_lists[index]
        index += 1;
      }
    }
  });
}
support_staff_job_history_detail_placeholder();

//cocoon
$('#support-staff-history-fields').on('cocoon:after-insert', function() {
  check_to_hide_or_show_add_link();
  support_staff_job_history_year_placeholder();
  support_staff_job_history_detail_placeholder();
});

$('#support-staff-history-fields').on('cocoon:after-remove', function() {
  check_to_hide_or_show_add_link();
});

$('#deduction-fields').on('cocoon:after-insert', function(e) {
  setNumberEventListener();
  setPlaceholder();
});

$('#allowance-fields').on('cocoon:after-insert', function(e) {
  setNumberEventListener();
  setPlaceholder();
});

$('#hasPaidCostToOwnCountryOrganization').on('cocoon:after-insert', function(e) {
  setNumberEventListener();
});

$('#support-staff-history-fields').on('cocoon:after-insert', function(e) {
  setNumber();
});

// 【旧】ラジオボタンの値で関連項目を表示
$(document).on('change', '.toggle-radio', function() {
  const tarId = $(this).data('toggle-target-id');
  const toggle = $(this).data('toggle');
  if (toggle == 'show') {
    $('#' + tarId).slideDown();
    $('#' + tarId).find('.required-input').prop('required', true)
  } else {
    $('#' + tarId).slideUp();
    $('#' + tarId).find('input').prop('checked', false)
    $('#' + tarId).find('option').prop('selected', false)
    $('#' + tarId).find('.required-input').removeAttr('required');
  }
});

// 【新】ラジオボタンの値で関連項目を表示
$(document).on('change', '.toggle-show-radio-btn', function() {
  const tarId = $(this).data('toggle-target-id');
  if ($(this).val() == $(this).data('toggle-show')) {
    $('#' + tarId).slideDown();
    $('#' + tarId).find('.required-input').prop('required', true)
  } else {
    $('#' + tarId).slideUp();
    $('#' + tarId).find('input').prop('checked', false)
    $('#' + tarId).find('option').prop('selected', false)
    $('#' + tarId).find('.required-input').removeAttr('required');
  }
});

// ラジオボタンの値で表示内容を切り替え
$(document).on('change', '.toggle-switch-radio-btn', function() {
  const tarClass = $(this).data('switch-target-class');
  $('.' + tarClass).addClass('d-none');
  $('.' + tarClass + '.' + $(this).val() ).removeClass('d-none');
});

// ラジオボタンの値でdisables切り替え
$(document).on('change', '.toggle-disabled-radio-btn', function() {
  const tarId = $(this).data('toggle-target-id');
  if ($(this).val().toString() == $(this).data('toggle-disabled').toString()) {
    $('#' + tarId).removeClass('disabled-area');
  } else {
    $('#' + tarId).addClass('disabled-area');
    $('#' + tarId).find('input').val('')
  }
});

// ラジオボタンの値でdisables切り替え
$(document).on('change', '.toggle-disabled-radio-btn-for-allowance', function() {
  const tarId = $(this).data('toggle-target-id');
  if ($(this).val().toString() == $(this).data('toggle-disabled').toString()) {
    $('#' + tarId).removeClass('disabled-area');
  } else {
    $('#' + tarId).addClass('disabled-area');
    $('#' + tarId).find('input').each(function() {
      // Lấy tên của ô input
      var inputName = $(this).attr('name');
      // Kiểm tra xem tên có chứa '[id]' ở cuối không
      if (inputName && (inputName.endsWith('[id]') || inputName.endsWith('[is_fixed_monthly_payment]'))) {
          // Nếu có, không thực hiện set giá trị là rỗng
      } else {
          // Nếu không, thực hiện set giá trị là rỗng
          $(this).val('');
      }
    });
  }
});

// ラジオボタンの値でplaseholder切り替え
$(document).on('change', '.toggle-placeholder-radio-btn', function() {
  const tarId = $(this).data('toggle-target-id');
  $('#' + tarId).attr('placeholder', $('#' + tarId).data($(this).val() + '-placeholder') );
});

// 労働時間計算機
$(document).on('input', '#calc-num', function(){
  const annualResult = 52 * $(this).val();
  const monthlyResult = annualResult / 12;
  $('#monthly-result').html((monthlyResult).toFixed(2));
  $('#annual-result').html((annualResult).toFixed(2));
});

$(document).on('input', '#calc-num2', function(){
  const annualResult = 52 * $(this).val();
  const monthlyResult = annualResult / 12;
  $('#monthly-result-day').html(Math.floor(monthlyResult));
  $('#annual-result-day').html(Math.floor(annualResult));
});

function getStringFromDate(date) {
  var year_str = date.getFullYear();
  // 月は0月から始まるので+1すること
  var month_str =  ('0'+(date.getMonth() + 1)).slice(-2)
  var day_str = ('0'+date.getDate()).slice(-2)

  var format_str = 'YYYY-MM-DD';
  format_str = format_str.replace(/YYYY/g, year_str);
  format_str = format_str.replace(/MM/g, month_str);
  format_str = format_str.replace(/DD/g, day_str);

  return format_str;
};

// PDFダウンロード時のローディングアニメーション

$(document).ready(function() {
  $('.download-pdf').on('click', function() {
    var pdfUrl = $(this).data('pdfUrl');
    var pdfFilename = $(this).data('pdfFilename');
    $.ajax({
      url: pdfUrl,
      method: 'GET',
      xhrFields: {
        responseType: 'blob'
      },
      beforeSend: function() {
        $('.loading-animation').show();
      },
      complete: function() {
        $('.loading-animation').hide();
      },
      success: function (data) {
          var a = document.createElement('a');
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = pdfFilename;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
      },
      error: function () {
        alert('Something went wrong, please try again!');
      }
    });
  });
})

// question modal

$(document).on('click', '#questionModalButton', function() {
  var modalBackdrop = $('.modal-backdrop');
  modalBackdrop.css('background-color','transparent');
  $("body").css("overflow", "scroll");
});

$('.btn-regular-report').on('click', function() {
  $('.loading-animation').show();
});
